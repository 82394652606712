import React from "react";
import firebase from "firebase";
import { useAuth } from "reactfire";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Typography } from "antd";

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: ({ additionalUserInfo, user }) => {
      if (additionalUserInfo.isNewUser) {
        // user.sendEmailVerification({ url: window.location.href });
      }

      return false; // Avoid redirects after sign-in
    }
  },
  credentialHelper: "none"
};

function LoginPage() {
  const auth = useAuth();

  return (
    <div id="prezcall-auth-page-root" className="pt5 mw7 center prezcall-auth">
      <h1 className="tc f1 fw5">
        <Typography.Link
          href="https://prezcall.com"
          target="_blank"
          style={{ borderBottom: "1px dashed #999" }}
        >
          <span className="dim">PrezCall</span>
        </Typography.Link>{" "}
        Sign-in / Sign-up
      </h1>
      <div className="pt4">
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </div>
    </div>
  );
}

export default LoginPage;
