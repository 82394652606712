export default {
  page_title: "PrezCall - Dashboard",
  menu_my_teams: "My teams",
  menu_sign_out: "Sign out",
  setup_team: "Choose your team",
  setup_team_hint: "Teams let you collaborate with your coworkers.",
  setup_team_create_new: "Create new team",
  setup_team_join_team: "Join existing team",
  setup_team_no_teams: "You don't belong to any teams. Create or join one.",
  setup_team_provide_invitation_link:
    "Please insert an invitation link provided to you by team owner:",
  setup_team_join: "Join",
  setup_team_create_team_name: "Team name:",
  setup_team_create: "Create",
  team_invite_others: "Invite others to your team by sending this link:",
  my_sessions: "My sessions",
  my_sessions_hint: "Create and manage sessions with your prospects here.",
  new_session: "New session",
  no_sessions:
    "You haven't created any sessions yet. Time to create the first one!",
  date: "Date",
  file: "File",
  client: "Client",
  session_id: "Session ID",
  notes: "Notes",
  url_for_viewer: "Address for viewer:",
  session_end: "End session",
  participants: "Participants",
  presentation_file: "Presentation file (PDF)",
  choose_presentation: "Choose presentation",
  upload_new_file: "New file",
  sample_file: "Sample file",
  prospect: "Prospect / client",
  participant_left: "The participant has left the meeting.",
  waiting_for_participants: "Waiting for others to join...",
  share_screen: "Share screen",
  share_screen_end: "Stop sharing screen",
  no_presentation_selected: "No presentation file chosen.",
  choose_presentation_file: "Please specify a file",
  chat_type_message: "Message...",
  workspace: "Team",
  workspace_hint_1:
    "Here you can see activities of the whole team. It covers users, clients, notes and dates when the presentations were conducted.",
  workspace_hint_2:
    "You can also upload here PDF files to the document library that the entire team will be able to use when presenting.",
  all_sessions: "All sessions",
  user: "User",
  files: "Files",
  add_new_file: "Add new file:",
  file_name: "Name",
  file_updated: "Updated",
  file_size: "Size [KB]",
  users: "Users",
  account: "Account",
  settings: "Settings",
  email: "E-mail",
  footer: "Footer",
  logout: "Logout",
  session_loading_error: "Unexpected error occurred during loading session.",
  session_untitled: "untitled",
  session_date: "date",
  session_not_selected:
    "Session not selected. Please use the panel on the left to open session.",
  session_code: "Code",
  session_title: "Title",
  session_viewers_count: "Viewers",
  session_file_url: "Presentation URL",
  session_set_file_url: "Set URL",
  session_no_file_url:
    "Paste the presentation link from Google Drive. Remember to make the file public.",
  session_file_loading_error:
    "Unexpected error occurred during loading presentation.",
  presentation_in_use_removal_failure:
    "Presentation cannot be removed because it is used in other sessions.",
  added: "added",
  slide: "Slide",
  save: "Save",
  payment: "Payment",
  billing_info: "Subscription management",
  confirm_email: "Confirm your email address",
  email_verification_copy:
    "Your e-mail {{email}} is not verified. We have sent an e-mail with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link. If you do not receive a confirmation email, please check your spam folder.",
  resend_verification_email: "Resend the verification email",
  trial_end: "trial ends",
  account_configuring:
    "We are configuring your PrezCall account. It may take a few seconds.",
  account_configuring_help:
    "If it takes longer or there is any other problem, contact us via help@prezcall.com.",
  help: "To see the step-by-step guide on how to use Prezcall,",
  help_link_text: "click here",
  help_link: "https://prezcall.com/how-to"
};
