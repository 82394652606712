export default {
  page_title: "PrezCall - Panel administracyjny",
  menu_my_teams: "Moje teamy",
  menu_sign_out: "Wyloguj",
  setup_team: "Wybierz team",
  setup_team_hint: "PrezCall umożliwia kolaborację w ramach Twojego zespołu.",
  setup_team_create_new: "Utwórz nowy team",
  setup_team_join_team: "Dołącz do istniejącego teamu",
  setup_team_no_teams:
    "Twoje konto nie jest powiązane z żadnym teamem. Utwórz nowy lub dołącz do istniejącego.",
  setup_team_provide_invitation_link:
    "Wklej link otrzymany od administratora teamu:",
  setup_team_join: "Dołącz",
  setup_team_create_team_name: "Nazwa teamu:",
  setup_team_create: "Utwórz",
  team_invite_others: "Zaproś innych do swojego zespołu, wysyłając ten link:",
  my_sessions: "Moje sesje",
  my_sessions_hint: "",
  new_session: "Nowa sesja",
  no_sessions: "Nie utworzyłeś jeszcze żadnej sesji.",
  date: "Data",
  user: "User",
  file: "Plik",
  client: "Klient",
  session_id: "ID sesji",
  notes: "Notatki",
  url_for_viewer: "Adres dla odbiorcy:",
  session_end: "Zakończ sesję",
  participants: "Widzów",
  presentation_file: "Plik prezentacji (PDF)",
  choose_presentation: "Wybierz prezentację",
  upload_new_file: "Nowy plik",
  sample_file: "Przykładowy plik",
  prospect: "Prospekt / klient",
  participant_left: "Uczestnik opuścił sesję.",
  waiting_for_participants: "Oczekiwanie na uczestników...",
  share_screen: "Udostępnij ekran",
  share_screen_end: "Zakończ udostępnianie ekranu",
  no_presentation_selected: "Nie wybrano pliku prezentacji.",
  choose_presentation_file: "Wybierz plik PDF z prezentacją",
  chat_type_message: "Wiadomość...",
  workspace: "Team",
  workspace_hint_1:
    "Tutaj możesz zobaczyć aktywność całego zespołu - użytkowników, klientów, notatki i daty przeprowadzania prezentacji.",
  workspace_hint_2:
    "W tym miejscu możesz również załadować pliki PDF z których Twój zespół będzie mógł skorzystać podczas prezentacji.",
  all_sessions: "Wszystkie sesje",
  files: "Pliki prezentacji",
  add_new_file: "Dodaj nowy plik:",
  file_name: "Nazwa",
  file_updated: "Aktualizowany",
  file_size: "Rozmiar [KB]",
  users: "Team",
  account: "Konto",
  settings: "Ustawienia",
  email: "E-mail",
  footer: "Stopka",
  logout: "Wyloguj",
  session_loading_error: "Wystąpił błąd pobierania sesji.",
  session_untitled: "bez tytułu",
  session_date: "data",
  session_not_selected:
    "Nie wybrałeś żadnej sesji. Aby rozpocząć, wybierz sesję z panelu po lewej stronie.",
  session_code: "Kod",
  session_title: "Tytuł",
  session_viewers_count: "Widzów",
  session_file_url: "URL prezentacji",
  session_set_file_url: "Ustaw URL",
  session_no_file_url:
    "Wklej link do prezentacji z Google Drive. Pamiętaj, żeby ustawić plik jako publiczny.",
  session_file_loading_error: "Wystąpił błąd ładowania prezentacji.",
  presentation_in_use_removal_failure:
    "Nie można usunąć prezentacji ponieważ jest używana w innych sesjach.",
  added: "dodano",
  slide: "Slajd",
  save: "Zapisz",
  payment: "Płatność",
  billing_info: "Zarządzanie subskrybcją",
  confirm_email: "Potwierdź swój adres email",
  email_verification_copy:
    "Twój e-mail {{email}} nie został zweryfikowany. Kliknij w link zawarty w wiadomości otrzymanej od nas aby dokończyć proces rejestracji. Jeżeli nie otrzymałeś/aś wiadomości od nas, sprawdź skrzynkę spam lub kosz.",
  resend_verification_email: "Wyślij ponownie e-mail",
  trial_end: "okres próbny kończy się",
  account_configuring:
    "Konfigurujemy Twoje konto PrezCall. To może chwilkę potrwać.",
  account_configuring_help:
    "Jeśli trwa to dłużej albo pojawia się inny problem, skontaktuj się z nami poprzez help@prezcall.com.",
  help: "Żeby zobaczyć krok po kroku jak używać Prezcalla, kliknij tutaj,",
  help_link_text: "kliknij tutaj",
  help_link: "https://prezcall.com/pl/krok-po-kroku"
};
