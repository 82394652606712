import { useRef, useEffect } from "react";
import { matchPath } from "react-router-dom";

export const formatFirebaseDate = date =>
  date && date.toDate().toLocaleString();

export const formatFirebaseDate2 = date =>
  date && new Date(date?._seconds * 1000).toLocaleString();

export const getFileNameFromPath = (path, workspaceId) =>
  matchPath(path, {
    path: `workspaces/${workspaceId}/:fileId`,
    isStrict: false
  })?.params?.fileId;

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
