import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, PageHeader } from "antd";
import { useFunctions } from "reactfire";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";

function WorkspaceJoin() {
  const functions = useFunctions();
  const history = useHistory();
  const { t } = useTranslation();
  const joinWorkspace = useAsync({
    deferFn: async ([id]) => {
      await functions.httpsCallable("joinWorkspace")({
        id
      });
      return id;
    },
    onResolve: id => history.push(`/w/${id}`)
  });

  const onFinish = ({ link }) => {
    // ToDo: validation
    if (!link) {
      alert("Please specify invitation link");
      return;
    }

    const x = link.split("/");
    const lastIndex = x.length - 1;

    if (x[lastIndex - 1] !== "invite") {
      alert("Incorrect invitation link");
      return;
    }

    joinWorkspace.run(x[lastIndex]);
  };

  return (
    <div className="mw7 center pt5">
      <PageHeader
        ghost={false}
        onBack={() => history.push("/workspaces")}
        title={t("setup_team_join_team")}
        className="pc-shadow br2"
      >
        <Form
          className="mt3"
          initialValues={{ link: "" }}
          size="large"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label={t("setup_team_provide_invitation_link")}
            name="link"
          >
            <Input placeholder="Invitation link" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={joinWorkspace.isPending}
            >
              {t("setup_team_join")}
            </Button>
          </Form.Item>
        </Form>
      </PageHeader>
    </div>
  );
}

export default WorkspaceJoin;
