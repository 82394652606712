// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { useAuth } from "reactfire";
import { useResetRecoilState } from "recoil";
import { profileState } from "../store";

/**
 * @return {null}
 */
function Logout() {
  const auth = useAuth();
  const resetProfileState = useResetRecoilState(profileState);

  useEffect(() => {
    resetProfileState();
    auth.signOut();
  }, []); // eslint-disable-line

  return null;
}

export default Logout;
