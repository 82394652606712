import { atom } from "recoil";

export const counterState = atom({
  key: "CounterState",
  default: 0
});

export const profileState = atom({
  key: "ProfileState",
  default: {
    workspaces: [],
    selectedWorkspace: null
  }
});
