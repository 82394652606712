import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  useUser,
  useFirestore,
  useFunctions,
  useFirestoreCollection
} from "reactfire";
import { useAsync } from "react-async";
import qs from "query-string";
import { Button, Table, Modal, Empty, ConfigProvider } from "antd";
import { getFileNameFromPath } from "../utils";
import NewSession from "./NewSession";
import { useTranslation } from "react-i18next";

function Main() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const params = useParams();
  const { workspaceId } = params;
  const firestore = useFirestore();
  const functions = useFunctions();
  const user = useUser();
  const userSessionsRef = firestore.collection(
    `workspaces/${workspaceId}/sessions`
  );
  const searchParsed = qs.parse(search);
  const { newSession } = searchParsed;
  const createSessionRequest = useAsync({
    deferFn: async ([props]) => {
      const { data } = await functions.httpsCallable("createSession")({
        workspaceId,
        ...props
      });

      return data.id;
    },
    onResolve: id =>
      history.push({
        search: qs.stringify({ ...searchParsed, newSession: id })
      })
  });

  const sessions = [];
  useFirestoreCollection(
    userSessionsRef.where("user", "==", user.uid).orderBy("createdTime", "desc")
  ).forEach(doc => {
    sessions.push({ id: doc.id, ...doc.data() });
  });

  const columns = [
    {
      title: t("date"),
      dataIndex: "createdTime",
      key: "createdTime",
      width: 200,
      render: date => date && date.toDate().toLocaleString()
    },
    {
      title: t("file"),
      dataIndex: "resource",
      key: "resource",
      width: 400,
      render: resource =>
        resource && getFileNameFromPath(resource?.filePath, workspaceId)
    },
    {
      title: t("client"),
      dataIndex: "client",
      key: "client"
    }
  ];

  return (
    <div className="mw8 center mv5">
      <div className="mb4">
        <h1>{t("my_sessions")}</h1>
        <div className="f5">
          <p className="lh-copy">{t("my_sessions_hint")}</p>
        </div>
      </div>
      <div className="bg-white pa4 pc-shadow br2 ba b--black-10">
        <div className="mb3">
          <Button
            type="primary"
            onClick={() => createSessionRequest.run()}
            loading={createSessionRequest.isPending}
            size="large"
          >
            {t("new_session")}
          </Button>
        </div>
        <ConfigProvider
          renderEmpty={() => <Empty description={t("no_sessions")} />}
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={sessions}
            bordered
            pagination={false}
            size="small"
            expandable={{
              expandedRowRender: ({ id, notes }) => (
                <div className="pa3">
                  <div>
                    <div className="f6 fw6 mb2">{t("session_id")}</div>
                    <div>{id}</div>
                  </div>
                  <div className="mt2">
                    <div className="f6 fw6 mb2">{t("notes")}</div>
                    <div>{notes || <span className="black-30">n/a</span>}</div>
                  </div>
                </div>
              ),
              rowExpandable: () => true
            }}
          />
        </ConfigProvider>
      </div>
      <Modal
        title={null}
        closable={false}
        visible={!!newSession}
        style={{ top: 0 }}
        bodyStyle={{ padding: 0, minHeight: "100vh" }}
        wrapClassName="new-session-modal w-100 h-100"
        width="100%"
        footer={null}
        destroyOnClose
      >
        <NewSession workspaceId={workspaceId} sessionId={newSession} />
      </Modal>
    </div>
  );
}

export default Main;
