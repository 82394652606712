import React from "react";
import { useAsync } from "react-async";
import { useHistory } from "react-router-dom";
import { useFunctions } from "reactfire";
import { PageHeader, Button, Form, Input } from "antd";
import { useSetRecoilState } from "recoil";
import { profileState } from "../store";
import { useTranslation } from "react-i18next";

function WorkspaceWizard() {
  const { t } = useTranslation();
  const setProfile = useSetRecoilState(profileState);
  const createWorkspace = useFunctions().httpsCallable("createWorkspace");
  const fetchUserProfile = useFunctions().httpsCallable("fetchUserProfile");
  const history = useHistory();
  const createWorkspaceRequest = useAsync({
    deferFn: async ([data]) => {
      await createWorkspace(data);
      return (await fetchUserProfile()).data;
    },
    onResolve: profile => {
      setProfile(profile);
      history.push(`/workspaces/created/${profile.selectedWorkspace}`);
    }
  });

  const onFinish = ({ name }) => {
    // ToDo: validation
    if (!name) {
      alert("Please specify name");
    }
    createWorkspaceRequest.run({ name });
  };

  return (
    <div className="mw7 center pt5">
      <PageHeader
        ghost={false}
        onBack={() => history.push("/workspaces")}
        title={t("setup_team_create_new")}
        className="pc-shadow br2"
      >
        <Form
          className="mt3"
          initialValues={{ name: "" }}
          size="large"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item label={t("setup_team_create_team_name")} name="name">
            <Input placeholder="i.e. Acme Corporation" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={createWorkspaceRequest.isPending}
            >
              {t("setup_team_create")}
            </Button>
          </Form.Item>
        </Form>
      </PageHeader>
    </div>
  );
}

export default WorkspaceWizard;
