import React from "react";
import { Layout as AntLayout } from "antd";
import Header from "./Header";

function Layout({ children }) {
  return (
    <AntLayout>
      <Header />
      <AntLayout.Content
        className="site-layout"
        style={{ padding: "0 50px", marginTop: 64 }}
      >
        {children}
      </AntLayout.Content>
    </AntLayout>
  );
}

export function SectionLayout({ style, className = "", children }) {
  const base = "bg-white ph4 pv3 pc-shadow br2 ba b--black-10";
  const classes = `${base}${className && ` ${className}`}`;

  return (
    <div style={style} className={classes}>
      {children}
    </div>
  );
}

export default Layout;
