import React from "react";
import { Tabs, PageHeader, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
// import { useFunctions } from "reactfire";
// import { useAsync } from "react-async";
import WorkspaceSessions from "./WorkspaceSessions";
import WorkspaceFiles from "./WorkspaceFiles";
import WorkspaceUsers from "./WorkspaceUsers";
import { SectionLayout } from "./Layout";
import { profileState } from "../store";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

function Workspace() {
  const { t } = useTranslation();
  // const functions = useFunctions();
  const { workspaceId } = useParams();
  const { workspaces } = useRecoilValue(profileState);
  const workspace = workspaces.find(({ id }) => id === workspaceId);
  const invitationLink = `${window.location.origin}/invite/${workspaceId}`;
  /*
  const getBillingUrl = useAsync({
    deferFn: () =>
      functions.httpsCallable("billing_portal")({
        workspaceId,
        return_url: window.location.href
      }),
    onResolve: ({ data }) => {
      window.location.href = data.url;
    }
  });

  const handleBillingPage = () => {
    getBillingUrl.run();
  };
  */

  return (
    <div className="mw8 center pt5">
      <div className="mb4">
        <h1>{t("workspace")}</h1>
        <p className="f5 lh-copy">{t("workspace_hint_1")}</p>
        <p className="f5 lh-copy">{t("workspace_hint_2")}</p>
      </div>
      <SectionLayout>
        <PageHeader
          ghost={false}
          title={
            <div className="mb2">
              <span className="fw4">team:</span> {workspace?.name}
            </div>
          }
          /*
          extra={
            <Button
              onClick={handleBillingPage}
              loading={getBillingUrl.isPending}
            >
              Billing
            </Button>
          }
          */
        >
          <div className="mb4">
            {t("team_invite_others")}{" "}
            <Typography.Text copyable code>
              {invitationLink}
            </Typography.Text>
          </div>
          <Tabs defaultActiveKey="sessions">
            <TabPane tab={t("all_sessions")} key="sessions">
              <WorkspaceSessions workspaceId={workspaceId} />
            </TabPane>
            <TabPane tab={t("files")} key="files">
              <WorkspaceFiles workspaceId={workspaceId} />
            </TabPane>
            <TabPane tab={t("users")} key="users">
              <WorkspaceUsers workspaceId={workspaceId} />
            </TabPane>
          </Tabs>
        </PageHeader>
      </SectionLayout>
    </div>
  );
}

export default Workspace;
