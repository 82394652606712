export const LOCALES = {
  en_US: "en_US",
  pl_PL: "pl_PL"
};

export const LANGUAGES = [
  { value: LOCALES.en_US, label: "🇬🇧 EN" },
  { value: LOCALES.pl_PL, label: "🇵🇱 PL" }
];

export const streamCredentials = {
  consumerKey: "FVCIFZaI3QB4k4W9fantXw==",
  consumerSecret: "skGqS3db1JYdlGrSbh6-XlReJXdpS3Ecn-LtADZtN9w="
};
