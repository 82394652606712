import React, { Fragment, useEffect, useState, useRef } from "react";
import { useAsync } from "react-async";
import {
  useFirestore,
  useFunctions,
  useFirestoreDocData,
  useStorage,
  useUser
} from "reactfire";
import { useHistory, useLocation } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import {
  Button,
  Spin,
  Select,
  Space,
  Divider,
  Statistic,
  Typography,
  Input,
  Affix,
  Empty,
  Alert,
  Form
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";
import { debounce } from "lodash-es";
import qs from "query-string";
import Chat from "./Chat";
import PDFViewer from "./PDFViewer";
import { getFileNameFromPath, usePrevious } from "../utils";
import { useTranslation } from "react-i18next";
import VideoCall from "./VideoCall";

function Session({ workspaceId, sessionId }) {
  const { t } = useTranslation();
  const fileSelectRef = useRef(null);
  const [fileSelectHighlighted, setFileSelectHighlighted] = useState(false);
  const [participantCount, setParticipantCount] = useState(null);
  const prevParticipantCount = usePrevious(participantCount);
  const { search } = useLocation();
  const history = useHistory();
  const functions = useFunctions();
  const firestore = useFirestore();
  const storage = useStorage();
  const user = useUser();
  const sessionRef = firestore.doc(
    `workspaces/${workspaceId}/sessions/${sessionId}`
  );
  const sessionToken = useAsync({
    deferFn: async () =>
      (await functions.httpsCallable("findSessionToken")({
        sessionId
      })).data
  });
  const workspaceFiles = useAsync({
    deferFn: async () => {
      return (await functions.httpsCallable("listWorkspaceFiles")({
        workspaceId
      })).data;
    }
  });
  const fileDownloadUrlRequest = useAsync({
    deferFn: async ([path]) => {
      return await storage
        .ref()
        .child(path)
        .getDownloadURL();
    },
    onResolve: async fileDownloadUrl => {
      await sessionRef.set(
        {
          resource: {
            fileDownloadUrl
          }
        },
        { merge: true }
      );
    }
  });
  const uploadFile = useAsync({
    deferFn: async ([file]) => {
      const filePath = `workspaces/${workspaceId}/${file.name}`;
      await storage
        .ref()
        .child(filePath)
        .put(file);
      return await onFilePathChange(filePath);
    },
    onResolve: () => {
      workspaceFiles.run(workspaceId);
    }
  });

  const session = useFirestoreDocData(sessionRef);
  const videoCall = session?.videoCall || false;
  // const createdTime = session?.createdTime;
  const client = session?.client || "";
  const notes = session?.notes || "";
  const filePath = session?.resource?.filePath;
  const fileDownloadUrl = session?.resource?.fileDownloadUrl;
  const currentPageIndex = session?.resource?.currentPageIndex;

  const setClient = async client => {
    await sessionRef.set(
      {
        client
      },
      { merge: true }
    );
  };
  const setClientDebounced = debounce(setClient, 300);

  const setNotes = async notes => {
    await sessionRef.set(
      {
        notes
      },
      { merge: true }
    );
  };
  const setNotesDebounced = debounce(setNotes, 300);

  useBeforeunload(async () => {
    if (videoCall) {
      await sessionRef.set(
        {
          videoCall: false
        },
        { merge: true }
      );
    }
  });

  useEffect(() => {
    return () => {
      setClientDebounced.flush();
      setNotesDebounced.flush();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    sessionToken.run();
  }, [sessionId]); // eslint-disable-line

  useEffect(() => {
    workspaceFiles.run();
  }, [workspaceId]); // eslint-disable-line

  useEffect(() => {
    if (!filePath) {
      return;
    }

    fileDownloadUrlRequest.run(filePath);
  }, [filePath]); // eslint-disable-line

  useEffect(() => {
    if (fileSelectHighlighted && fileSelectRef.current) {
      fileSelectRef.current.focus();
    }
  }, [fileSelectHighlighted]); // eslint-disable-line

  const allParticipantsLeft =
    participantCount === 0 && prevParticipantCount > participantCount;

  const onFilePathChange = async filePath => {
    await sessionRef.set(
      {
        resource: {
          filePath,
          currentPageIndex: 0
        }
      },
      { merge: true }
    );
  };

  const onPageChange = async currentPageIndex => {
    await sessionRef.set(
      {
        resource: {
          currentPageIndex
        }
      },
      { merge: true }
    );
  };

  const onVideoCall2 = async () => {
    await sessionRef.set(
      {
        videoCall: true
      },
      { merge: true }
    );
  };

  const handleOnLeave2 = async () => {
    await sessionRef.set(
      {
        videoCall: false
      },
      { merge: true }
    );
  };

  const onFileChange = ({ target }) => {
    const [file] = target.files;
    uploadFile.run(file);
    target.value = "";
  };

  const files = workspaceFiles.data || [];

  return (
    <Fragment>
      <Affix
        style={{ zIndex: 9999 }}
        target={() =>
          document.querySelector(".new-session-modal.ant-modal-wrap")
        }
      >
        <div
          className="bg-near-white br2 pa3 bb b--black-05 bottom-shadow"
          style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="f4 fw6">{t("new_session")}</div>
              <div className="ml3" style={{ fontSize: "1.1rem" }}>
                <Typography.Text code>
                  {t("url_for_viewer")} <strong>pinprez.com</strong>; PIN code:{" "}
                  {sessionToken.isSettled ? (
                    <strong>{sessionToken.data?.token}</strong>
                  ) : (
                    <Spin size="small" />
                  )}
                </Typography.Text>
              </div>
            </div>
            <div>
              <Button
                className="btn-success"
                size="large"
                onClick={() => {
                  const { newSession, ...restSearch } = qs.parse(search);
                  history.push({
                    search: qs.stringify(restSearch)
                  });
                }}
              >
                {t("session_end")}
              </Button>
            </div>
          </div>
        </div>
      </Affix>
      <div className="pa4">
        <div className="mb3">
          <Space size={50} align="start">
            <Statistic
              title="PIN code"
              formatter={() =>
                sessionToken.isSettled ? (
                  sessionToken.data?.token
                ) : (
                  <Spin size="small" />
                )
              }
            />
            <Statistic
              title={t("participants")}
              formatter={() =>
                participantCount === null ? (
                  <Spin size="small" />
                ) : (
                  participantCount
                )
              }
            />
            <Statistic
              title={t("presentation_file")}
              formatter={() => (
                <div className="flex flex-column">
                  <Form.Item
                    {...fileSelectHighlighted &&
                      !filePath && {
                        hasFeedback: true,
                        validateStatus: "warning"
                      }}
                  >
                    <Select
                      ref={fileSelectRef}
                      style={{ width: 300 }}
                      placeholder={t("choose_presentation")}
                      value={
                        filePath && getFileNameFromPath(filePath, workspaceId)
                      }
                      onSelect={() => setFileSelectHighlighted(false)}
                      onChange={onFilePathChange}
                      loading={
                        !workspaceFiles.isSettled || uploadFile.isPending
                      }
                      dropdownRender={menu => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            className="justify-between items-center"
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8
                            }}
                          >
                            <Spin spinning={uploadFile.isPending}>
                              <label
                                htmlFor="chooseFile"
                                className="pointer black dim"
                              >
                                <PlusOutlined /> {t("upload_new_file")}
                              </label>
                              <input
                                id="chooseFile"
                                accept="application/pdf"
                                type="file"
                                style={{ display: "none" }}
                                onChange={onFileChange}
                              />
                            </Spin>
                            <div>
                              <Button
                                href="https://drive.google.com/uc?export=download&id=1gfKcR-lWtH0I3eWbRc8JCPY5ezelyRWJ"
                                target="_blank"
                                type="primary"
                                size="small"
                              >
                                <DownloadOutlined /> {t("sample_file")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    >
                      >
                      {files.map(({ name }, i) => (
                        <Select.Option key={i} value={name}>
                          {getFileNameFromPath(name, workspaceId)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              )}
            />
            <Statistic
              title={t("prospect")}
              formatter={() => (
                <Input
                  placeholder="XYZ ltd."
                  defaultValue={client}
                  maxLength={30}
                  onChange={({ target }) => setClientDebounced(target.value)}
                />
              )}
            />
            <Statistic
              title={t("notes")}
              formatter={() => (
                <Input.TextArea
                  rows={2}
                  defaultValue={notes}
                  onChange={({ target }) => setNotesDebounced(target.value)}
                  style={{ width: 300 }}
                  maxLength={500}
                />
              )}
            />
          </Space>
        </div>
        <Divider style={{ margin: 0 }} />
        <div className="ph2 pb4 flex">
          <div className="flex-grow-1">
            {participantCount === 0 && (
              <div className="mt3">
                <Alert
                  type="info"
                  showIcon={false}
                  className="tc"
                  closable={allParticipantsLeft}
                  message={
                    <span className="fw5">
                      {allParticipantsLeft
                        ? t("participant_left")
                        : t("waiting_for_participants")}
                    </span>
                  }
                />
              </div>
            )}
            <div className="mt3 flex justify-end">
              <Space>
                <Button
                  onClick={onVideoCall2}
                  disabled={!sessionToken.data?.token || videoCall}
                  icon={<VideoCameraOutlined />}
                >
                  VideoCall
                </Button>
              </Space>
            </div>
            {fileDownloadUrl ? (
              <div className="mt2 relative" style={{ minHeight: 500 }}>
                <PDFViewer
                  documentUrl={fileDownloadUrl}
                  currentPageIndex={currentPageIndex}
                  onPageChange={onPageChange}
                  workspaceId={workspaceId}
                  sessionId={sessionId}
                />
              </div>
            ) : (
              <div className="mt4">
                <Empty description={t("no_presentation_selected")}>
                  <Button
                    type="dashed"
                    onClick={() => setFileSelectHighlighted(true)}
                  >
                    {t("choose_presentation_file")}
                  </Button>
                </Empty>
              </div>
            )}
          </div>
          <div className="ml4 mt3 w-25">
            <Chat
              sessionId={sessionId}
              setParticipantCount={setParticipantCount}
            />
          </div>
        </div>
      </div>
      {sessionToken.isSettled && videoCall && (
        <VideoCall
          room={sessionToken.data?.token}
          handleOnLeave={handleOnLeave2}
          name={user.email}
        />
      )}
    </Fragment>
  );
}

export default Session;
