import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ConfigProvider, Empty, List, Space, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useAsync } from "react-async";
import { useFirestore, useUser } from "reactfire";
import { profileState } from "../store";
import { SectionLayout } from "./Layout";
import { useTranslation } from "react-i18next";

function WorkspaceList() {
  const history = useHistory();
  const [profile] = useRecoilState(profileState);
  const setProfile = useSetRecoilState(profileState);
  const { t } = useTranslation();
  const { workspaces } = profile;
  const firestore = useFirestore();
  const user = useUser();
  const userRef = firestore.doc(`users/${user.uid}`);
  const selectWorkspace = useAsync({
    deferFn: async ([selectedWorkspace]) => {
      await userRef.set(
        {
          selectedWorkspace
        },
        { merge: true }
      );
      setProfile({ ...profile, selectedWorkspace });
      history.push(`/w/${selectedWorkspace}`);
    }
  });

  return (
    <div className="mw7 center pt5">
      <div className="mb4">
        <h1>{t("setup_team")}</h1>
        <p className="f5 lh-copy">{t("setup_team_hint")}</p>
      </div>
      <SectionLayout>
        <ConfigProvider
          renderEmpty={() => <Empty description={t("setup_team_no_teams")} />}
        >
          <List
            header={
              <Space>
                <Button
                  type="primary"
                  onClick={() => history.push("/workspaces/new")}
                >
                  {t("setup_team_create_new")}
                </Button>
                <Button onClick={() => history.push("/workspaces/join")}>
                  {t("setup_team_join_team")}
                </Button>
              </Space>
            }
            size="large"
            dataSource={workspaces}
            renderItem={({ id, name }) => (
              <List.Item
                onClick={() => selectWorkspace.run(id)}
                className="ph3 link pointer bg-animate hover-bg-light-gray br1"
                extra={<RightOutlined />}
              >
                <List.Item.Meta
                  title={<span className="pointer dim">{name}</span>}
                />
              </List.Item>
            )}
          />
        </ConfigProvider>
      </SectionLayout>
    </div>
  );
}

export default WorkspaceList;
