import React, { useEffect } from "react";
import { useFunctions, useFirestore, useUser } from "reactfire";
import { useRecoilState } from "recoil";
import detectBrowserLanguage from "detect-browser-language";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { profileState } from "../store";

function AppContainer({ children }) {
  const { i18n } = useTranslation();
  const functions = useFunctions();
  const firestore = useFirestore();
  const user = useUser();
  const [profile, setProfile] = useRecoilState(profileState);
  const fetchUserProfile = useAsync({
    deferFn: async () => {
      const { data } = await functions.httpsCallable("fetchUserProfile")();
      const { lang = detectBrowserLanguage(), ...props } = data;
      setProfile({ ...props, lang });
      return data;
    }
  });

  useEffect(() => {
    fetchUserProfile.run();
  }, []); // eslint-disable-line

  const _user = user && { uid: user?.uid, email: user?.email };
  useEffect(() => {
    if (!_user) {
      return;
    }

    window.Upscope("updateConnection", {
      uniqueId: _user.uid,
      identities: [_user.email]
    });
  }, [JSON.stringify(_user)]); // eslint-disable-line

  const isSettled = fetchUserProfile.isSettled;
  const lang = fetchUserProfile.data?.data?.lang;
  useEffect(() => {
    if (isSettled && !lang) {
      firestore
        .doc(`users/${user.uid}`)
        .set({ lang: detectBrowserLanguage() }, { merge: true });
    }
  }, [isSettled, lang]); // eslint-disable-line

  const langLocal = profile?.lang;
  const i18nLang = i18n.language;
  useEffect(() => {
    const langReplaced = langLocal && langLocal.replace("-", "_");
    if (i18nLang !== langReplaced) {
      i18n.changeLanguage(langReplaced);
    }
  }, [i18nLang, langLocal]); // eslint-disable-line

  if (!fetchUserProfile.isSettled) {
    return <Loader full className="pt6" />;
  }

  return children;
}

export default AppContainer;
