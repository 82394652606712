import React, { useRef, useState, useEffect } from "react";
import {
  Chat as VendorChat,
  Channel,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import { useUser } from "reactfire";
import { useAsync } from "react-async";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const streamApiKey = "87crp5aegjjm";

const Chat = ({ sessionId, setParticipantCount }) => {
  const { t } = useTranslation();
  const user = useUser();
  const chatClient = useRef(new StreamChat(streamApiKey));
  const [channel, setChannel] = useState(null);
  const setChatUserRequest = useAsync({
    deferFn: () =>
      chatClient.current.setGuestUser({ id: user.uid, name: user.email }),
    onResolve: () => {
      setChannel(chatClient.current.channel("messaging", sessionId));
    }
  });

  useEffect(() => {
    if (!channel) {
      return;
    }

    const eventHandler = () => {
      setParticipantCount(channel.state.watcher_count - 1);
    };
    channel.on(eventHandler);

    return () => channel.off(eventHandler);
  }, [channel]); // eslint-disable-line

  useEffect(() => {
    setChatUserRequest.run();
  }, [sessionId]); // eslint-disable-line

  if (!setChatUserRequest.isSettled) {
    return <Loader fullX />;
  }

  return (
    <VendorChat client={chatClient.current} theme="messaging light">
      <Channel channel={channel} LoadingIndicator={() => <Loader fullX />}>
        <Window>
          <ChannelHeader title="Chat" />
          <MessageList />
          <MessageInput
            additionalTextareaProps={{ placeholder: t("chat_type_message") }}
          />
        </Window>
      </Channel>
    </VendorChat>
  );
};

export default Chat;
