import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Button } from "antd";
import { useTranslation } from "react-i18next";

function WorkspaceJoin() {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspaceId } = useParams();
  const invitationLink = `${window.location.origin}/invite/${workspaceId}`;

  return (
    <div className="mw6 center pt5">
      <div className="f3 fw5 mb3">{t("team_invite_others")}</div>
      <div className="f5 mb4">
        <Typography.Text copyable code>
          {invitationLink}
        </Typography.Text>
      </div>
      <div>
        <Button
          block
          type="primary"
          onClick={() => history.push(`/w/${workspaceId}`)}
          size="large"
        >
          OK
        </Button>
      </div>
    </div>
  );
}

export default WorkspaceJoin;
