import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useFunctions } from "reactfire";
import { useAsync } from "react-async";
import { Button } from "antd";
import { useRecoilValue } from "recoil";
import Loader from "./Loader";
import { profileState } from "../store";

function WorkspaceInvite() {
  const history = useHistory();
  const { workspaces } = useRecoilValue(profileState);
  const { workspaceId } = useParams();
  const functions = useFunctions();
  const workspaceData = useAsync({
    deferFn: async id => {
      return (await functions.httpsCallable("getWorkspaceData")({
        id
      })).data;
    }
  });
  const joinWorkspace = useAsync({
    deferFn: async id => {
      return (await functions.httpsCallable("joinWorkspace")({
        id
      })).data;
    },
    onResolve: () => history.push(`/w/${workspaceId}`)
  });
  const workspace = workspaces.find(({ id }) => id === workspaceId);

  useEffect(() => {
    if (!workspace) {
      workspaceData.run(workspaceId);
    }
  }, [workspace, workspaceId]); // eslint-disable-line

  let content;
  if (workspace) {
    content = (
      <div className="f4">
        You already belong to workspace <strong>{workspace.name}</strong>.
      </div>
    );
  } else {
    content = !workspaceData.isSettled ? (
      <Loader full />
    ) : (
      <div>
        <div className="f4">
          You are about to join team: <strong>{workspaceData.data.name}</strong>
        </div>
        <div className="mt3">
          <Button
            type="primary"
            onClick={() => joinWorkspace.run(workspaceId)}
            loading={joinWorkspace.isPending}
          >
            Join
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="mw7 center pt5">
      <div className="bg-white pa4 shadow-1">{content}</div>
    </div>
  );
}

export default WorkspaceInvite;
