import React from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import { useUser } from "reactfire";
import { useRecoilValue } from "recoil";
import { Layout, Button, Dropdown, Menu } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { profileState } from "../store";

function Header() {
  const { t } = useTranslation();
  const { workspaces } = useRecoilValue(profileState);
  const user = useUser();
  const { pathname } = useLocation();
  const workspaceId = matchPath(pathname, {
    path: "/w/:workspaceId",
    isStrict: false
  })?.params?.workspaceId;
  const basePath = `/w/${workspaceId}`;
  const menuKeys = [
    {
      key: "userView",
      path: basePath,
      label: t("my_sessions"),
      selected: ["/w/:workspaceId", "/w/:workspaceId/s/:sessionId"].some(
        path => matchPath(pathname, { path })?.isExact
      )
    },
    {
      key: "workspaceView",
      path: `${basePath}/workspace`,
      label: "Team",
      selected: matchPath(pathname, { path: "/w/:workspaceId/workspace" })
        ?.isExact
    }
  ];
  const selectedKeys = menuKeys
    .filter(({ selected }) => selected)
    .map(({ key }) => key);
  const workspace = workspaces.find(({ id }) => id === workspaceId);

  return (
    <Layout.Header
      className="flex justify-between"
      style={{ position: "fixed", zIndex: 99, width: "100%" }}
    >
      <div className="flex">
        <div className="f4 ph3 fw5">PrezCall</div>
        {workspaceId && (
          <Menu mode="horizontal" selectedKeys={selectedKeys}>
            {menuKeys.map(({ key, path, label }) => (
              <Menu.Item key={key}>
                <Link to={path}>{label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </div>
      <div>
        <Dropdown
          placement="bottomRight"
          overlay={
            <Menu>
              <Menu.ItemGroup
                title={`Team${workspace ? `: ${workspace.name}` : ""}`}
              >
                <Menu.Item key="teams">
                  <Link to="/workspaces">{t("menu_my_teams")}</Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.Divider />
              <Menu.ItemGroup
                title={
                  <div>
                    <UserOutlined /> {user?.email}
                  </div>
                }
              >
                <Menu.Item key="logout">
                  <Link to="/logout">{t("menu_sign_out")}</Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          }
        >
          <Button>
            menu <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </Layout.Header>
  );
}

export default Header;
