import React, { useEffect, useState } from "react";
import { useAsync } from "react-async";
import {
  useFirestore,
  useFunctions,
  useFirestoreDocData,
  useStorage,
  useUser
} from "reactfire";
import { useParams, useHistory } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import {
  Button,
  Spin,
  Select,
  Space,
  PageHeader,
  Divider,
  Statistic,
  Typography,
  Input
} from "antd";
import { VideoCameraOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "lodash-es";
import Chat from "./Chat";
import VideoCall from "./VideoCall";
import PDFViewer from "./PDFViewer";
import { getFileNameFromPath } from "../utils";

function Session() {
  const [participantCount, setParticipantCount] = useState(null);
  const { workspaceId, sessionId } = useParams();
  const history = useHistory();
  const functions = useFunctions();
  const firestore = useFirestore();
  const storage = useStorage();
  const user = useUser();
  const sessionRef = firestore.doc(
    `workspaces/${workspaceId}/sessions/${sessionId}`
  );
  const sessionToken = useAsync({
    deferFn: async () =>
      (await functions.httpsCallable("findSessionToken")({
        sessionId
      })).data
  });
  const workspaceFiles = useAsync({
    deferFn: async () => {
      return (await functions.httpsCallable("listWorkspaceFiles")({
        workspaceId
      })).data;
    }
  });
  const fileDownloadUrlRequest = useAsync({
    deferFn: async ([path]) => {
      return await storage
        .ref()
        .child(path)
        .getDownloadURL();
    },
    onResolve: async fileDownloadUrl => {
      await sessionRef.set(
        {
          resource: {
            fileDownloadUrl
          }
        },
        { merge: true }
      );
    }
  });
  const uploadFile = useAsync({
    deferFn: async ([file]) => {
      const filePath = `workspaces/${workspaceId}/${file.name}`;
      await storage
        .ref()
        .child(filePath)
        .put(file);
      return await onFilePathChange(filePath);
    },
    onResolve: () => {
      workspaceFiles.run(workspaceId);
    }
  });

  const session = useFirestoreDocData(sessionRef);
  const videoCall = session?.videoCall || false;
  const videoCall2 = session?.videoCall2 || false;
  const createdTime = session?.createdTime;
  const client = session?.client || "";
  const notes = session?.notes || "";
  const filePath = session?.resource?.filePath;
  const fileDownloadUrl = session?.resource?.fileDownloadUrl;
  const currentPageIndex = session?.resource?.currentPageIndex;

  const setClient = async client => {
    await sessionRef.set(
      {
        client
      },
      { merge: true }
    );
  };
  const setClientDebounced = debounce(setClient, 300);

  const setNotes = async notes => {
    await sessionRef.set(
      {
        notes
      },
      { merge: true }
    );
  };
  const setNotesDebounced = debounce(setNotes, 300);

  useBeforeunload(async () => {
    if (videoCall) {
      await sessionRef.set(
        {
          videoCall: false
        },
        { merge: true }
      );
    }
  });

  useEffect(() => {
    return () => {
      setClientDebounced.flush();
      setNotesDebounced.flush();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    sessionToken.run();
  }, [sessionId]); // eslint-disable-line

  useEffect(() => {
    workspaceFiles.run();
  }, [workspaceId]); // eslint-disable-line

  useEffect(() => {
    if (!filePath) {
      return;
    }

    fileDownloadUrlRequest.run(filePath);
  }, [filePath]); // eslint-disable-line

  const onFilePathChange = async filePath => {
    await sessionRef.set(
      {
        resource: {
          filePath,
          currentPageIndex: 0
        }
      },
      { merge: true }
    );
  };

  const onPageChange = async currentPageIndex => {
    await sessionRef.set(
      {
        resource: {
          currentPageIndex
        }
      },
      { merge: true }
    );
  };

  const onVideoCall = async () => {
    await sessionRef.set(
      {
        videoCall: true
      },
      { merge: true }
    );
  };

  const handleOnLeave = async () => {
    await sessionRef.set(
      {
        videoCall: false
      },
      { merge: true }
    );
  };

  const onFileChange = ({ target }) => {
    const [file] = target.files;
    uploadFile.run(file);
    target.value = "";
  };

  const files = workspaceFiles.data || [];

  return (
    <div className="mw9 center mv5">
      <PageHeader
        className="shadow-1 "
        ghost={false}
        onBack={() => history.push(`/w/${workspaceId}`)}
        title="Session"
        subTitle={
          <Space size="middle">
            <span>id: {sessionId}</span>
            <span>created: {createdTime.toDate().toLocaleString()}</span>
          </Space>
        }
      >
        <Divider style={{ margin: 0 }} />
        <div className="mv3">
          <Space size={50} align="start">
            <Statistic
              title="PIN code"
              formatter={() =>
                sessionToken.isSettled ? (
                  sessionToken.data?.token
                ) : (
                  <Spin size="small" />
                )
              }
            />
            <Statistic
              title="Participants"
              formatter={() =>
                participantCount === null ? (
                  <Spin size="small" />
                ) : (
                  participantCount
                )
              }
            />
            <Statistic
              title="File"
              formatter={() => (
                <div className="flex flex-column">
                  <Select
                    style={{ width: 300 }}
                    placeholder="Choose presentation"
                    value={
                      filePath && getFileNameFromPath(filePath, workspaceId)
                    }
                    onChange={onFilePathChange}
                    loading={!workspaceFiles.isSettled || uploadFile.isPending}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          className="justify-between"
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8
                          }}
                        >
                          <Spin spinning={uploadFile.isPending}>
                            <label htmlFor="chooseFile" className="pointer">
                              <PlusOutlined /> Add new
                            </label>
                            <input
                              id="chooseFile"
                              accept="application/pdf"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onFileChange}
                            />
                          </Spin>
                          <div>
                            <Typography.Link
                              href="https://drive.google.com/uc?export=download&id=1gfKcR-lWtH0I3eWbRc8JCPY5ezelyRWJ"
                              target="_blank"
                            >
                              Sample file
                            </Typography.Link>
                          </div>
                        </div>
                      </div>
                    )}
                  >
                    >
                    {files.map(({ name }, i) => (
                      <Select.Option key={i} value={name}>
                        {getFileNameFromPath(name, workspaceId)}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            />
            <Statistic
              title="Prospect / client"
              formatter={() => (
                <Input
                  placeholder="XYZ ltd."
                  defaultValue={client}
                  maxLength={30}
                  onChange={({ target }) => setClientDebounced(target.value)}
                />
              )}
            />
            <Statistic
              title="Notes"
              formatter={() => (
                <Input.TextArea
                  rows={2}
                  defaultValue={notes}
                  onChange={({ target }) => setNotesDebounced(target.value)}
                  style={{ width: 300 }}
                  maxLength={500}
                />
              )}
            />
          </Space>
          <div className="mt2 f5">
            <Typography.Text code>
              Ask viewers to go to a link <strong>pinprez.com</strong> and type
              a PIN code:{" "}
              {sessionToken.isSettled ? (
                <strong>{sessionToken.data?.token}</strong>
              ) : (
                <Spin size="small" />
              )}
            </Typography.Text>
          </div>
        </div>
        <Divider style={{ margin: 0 }} />
        <div className="ph2 pb4 flex">
          <div className="flex-grow-1">
            {fileDownloadUrl ? (
              <div className="mt4 relative" style={{ minHeight: 500 }}>
                <PDFViewer
                  documentUrl={fileDownloadUrl}
                  currentPageIndex={currentPageIndex}
                  onPageChange={onPageChange}
                  workspaceId={workspaceId}
                  sessionId={sessionId}
                />
              </div>
            ) : (
              <p className="mt4">Choose file to present.</p>
            )}
          </div>
          <div className="ml4 mt3 w-25">
            <Button
              className="mb2"
              block
              onClick={onVideoCall}
              disabled={!sessionToken.data?.token}
              icon={<VideoCameraOutlined />}
            >
              VideoCall
            </Button>
            <Chat
              sessionId={sessionId}
              setParticipantCount={setParticipantCount}
            />
          </div>
        </div>
      </PageHeader>
      {sessionToken.isSettled && (videoCall || videoCall2) && (
        <VideoCall
          room={sessionToken.data?.token}
          handleOnLeave={handleOnLeave}
          name={user.email}
        />
      )}
    </div>
  );
}

export default Session;
