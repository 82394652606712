import React from "react";
import {
  reducer as voxeetReducer,
  ConferenceRoom,
  VoxeetProvider
} from "@voxeet/react-components";
import thunkMidleware from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { streamCredentials } from "../config";

const reducers = combineReducers({
  voxeet: voxeetReducer
});

const configureStore = () =>
  createStore(reducers, applyMiddleware(thunkMidleware));

function VideoCall({ room = "test", name, handleOnLeave = () => {} }) {
  return (
    <div>
      <VoxeetProvider store={configureStore()}>
        <ConferenceRoom
          autoJoin
          consumerKey={streamCredentials.consumerKey}
          consumerSecret={streamCredentials.consumerSecret}
          conferenceAlias={room}
          handleOnLeave={handleOnLeave}
          isAdmin
          // isWidget={false}
          kickOnHangUp
          displayActions={["mute", "share", "video", "attendees"]}
          shareActions={["screenshare", "videopresentation"]}
          isWebinars
          userInfo={{ name }}
          // isModal
        />
      </VoxeetProvider>
    </div>
  );
}

export default VideoCall;
