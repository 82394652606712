import React, { useEffect } from "react";
import { Table, Space, Spin } from "antd";
import { useAsync } from "react-async";
import { useFunctions, useStorage } from "reactfire";
import { getFileNameFromPath } from "../utils";
import { useTranslation } from "react-i18next";

function WorkspaceFiles({ workspaceId }) {
  const { t } = useTranslation();
  const functions = useFunctions();
  const storage = useStorage();
  const workspaceFiles = useAsync({
    deferFn: async () => {
      return (await functions.httpsCallable("listWorkspaceFiles")({
        workspaceId
      })).data;
    }
  });
  const uploadFile = useAsync({
    deferFn: ([file]) =>
      storage
        .ref()
        .child(`workspaces/${workspaceId}/${file.name}`)
        .put(file),
    onResolve: () => {
      workspaceFiles.run(workspaceId);
    }
  });

  useEffect(() => {
    workspaceFiles.run(workspaceId);
  }, [workspaceId]); // eslint-disable-line

  const onChange = ({ target }) => {
    const [file] = target.files;
    uploadFile.run(file);
    target.value = "";
  };

  const columns = [
    {
      title: t("file_name"),
      dataIndex: "name",
      key: "name",
      render: name => getFileNameFromPath(name, workspaceId)
    },
    /*
    {
      title: t("file_updated"),
      dataIndex: "updated",
      key: "updated",
      render: date => formatFirebaseDate2(date)
    },
    */
    {
      title: t("file_size"),
      dataIndex: "size",
      key: "size",
      render: size => `${size / 1000}`
    }
    /*
    {
      title: "",
      key: "action",
      width: 220,
      render: () => (
        <Space size="middle">
          <Typography.Link href="#">Show</Typography.Link>
          <Typography.Link href="#">Delete</Typography.Link>
        </Space>
      )
    }
    */
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={workspaceFiles.data || []}
        loading={workspaceFiles.isPending}
        bordered
        pagination={false}
        title={() => (
          <Space size="small">
            <span>{t("add_new_file")}</span>
            <Spin spinning={uploadFile.isPending}>
              <input accept="application/pdf" type="file" onChange={onChange} />
            </Spin>
          </Space>
        )}
        bord
      />
    </div>
  );
}

export default WorkspaceFiles;
