import React from "react";
import { Spin } from "antd";

function Loader({ full, fullX, style, className, children }) {
  const styles = {
    ...((full || fullX) && {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }),
    marginTop: "1rem",
    marginBottom: "1rem",
    ...(full ? { width: "100%", height: "100%" } : fullX && { width: "100%" }),
    ...style
  };

  return (
    <div style={styles} className={className}>
      <Spin>{children}</Spin>
    </div>
  );
}

export default Loader;
