import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { RecoilRoot } from "recoil";
import { FirebaseAppProvider, AuthCheck } from "reactfire";
import { pdfjs } from "react-pdf/dist/entry.webpack";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import "./index.less";
import AppContainer from "./components/AppContainer";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Main from "./components/Main";
import WorkspaceInvite from "./components/WorkspaceInvite";
import WorkspaceList from "./components/WorkspaceList";
import WorkspaceWizardFinal from "./components/WorkspaceWizardFinal";
import Workspace from "./components/Workspace";
import Session from "./components/Session";
import WorkspaceWizard from "./components/WorkspaceWizard";
import WorkspaceJoin from "./components/WorkspaceJoin";
import LoginPage from "./components/LoginPage";
import Logout from "./components/Logout";
import Loader from "./components/Loader";
import * as serviceWorker from "./serviceWorker";
import { firebaseConfig } from "./firebase";
import en_US from "./locales/en_US";
import pl_PL from "./locales/pl_PL";
import { LOCALES } from "./config";

import "@voxeet/react-components/dist/voxeet-react-components.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      [LOCALES.en_US]: {
        translation: en_US
      },
      [LOCALES.pl_PL]: {
        translation: pl_PL
      }
    },
    lng: LOCALES.en_US,
    fallbackLng: LOCALES.en_US,

    interpolation: {
      escapeValue: false
    }
  });

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

function PrivateRoute(props) {
  const location = useLocation();

  return (
    <Suspense fallback={<Loader full />}>
      <AuthCheck
        fallback={
          <Redirect
            to={{
              pathname: "/login",
              ...(location.pathname !== "/logout" && {
                state: { from: location }
              })
            }}
          />
        }
      >
        <Route {...props} />
      </AuthCheck>
    </Suspense>
  );
}

function LoginRoute(props) {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  return (
    <Route {...props}>
      <Suspense fallback={<Loader full />}>
        <AuthCheck fallback={<LoginPage />}>
          <Redirect to={from} />
        </AuthCheck>
      </Suspense>
    </Route>
  );
}

function Root() {
  return (
    <Switch>
      <LoginRoute path="/login" />
      <AppContainer>
        <Layout>
          <Switch>
            <PrivateRoute exact path="/">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/workspaces/new">
              <WorkspaceWizard />
            </PrivateRoute>
            <PrivateRoute path="/workspaces/join">
              <WorkspaceJoin />
            </PrivateRoute>
            <PrivateRoute path="/workspaces/created/:workspaceId">
              <WorkspaceWizardFinal />
            </PrivateRoute>
            <PrivateRoute path="/workspaces">
              <WorkspaceList />
            </PrivateRoute>
            <PrivateRoute path="/invite/:workspaceId">
              <WorkspaceInvite />
            </PrivateRoute>
            <PrivateRoute path="/w/:workspaceId/s/:sessionId">
              <Session />
            </PrivateRoute>
            <PrivateRoute path="/w/:workspaceId/workspace">
              <Workspace />
            </PrivateRoute>
            <PrivateRoute path="/w/:workspaceId">
              <Main />
            </PrivateRoute>
            <PrivateRoute path="/logout">
              <Logout />
            </PrivateRoute>
          </Switch>
        </Layout>
      </AppContainer>
    </Switch>
  );
}

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <RecoilRoot>
      <Router>
        <Suspense fallback={<Loader full />}>
          <Root />
        </Suspense>
      </Router>
    </RecoilRoot>
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
