import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import useResizeObserver from "use-resize-observer/polyfilled";
import { times } from "lodash-es";
import Loader from "./Loader";
import DrawArea from "./DrawArea";
import ImageGallery from "react-image-gallery";

function PDFViewer({
  documentUrl,
  currentPageIndex,
  onPageChange,
  workspaceId,
  sessionId
}) {
  const { ref, width } = useResizeObserver();
  const [loadingState, setLoadingState] = useState({
    state: "pending"
  });
  const totalPages = loadingState?.data?.numPages;

  return (
    <div>
      <div ref={ref}>
        <Document
          file={documentUrl}
          onLoadSuccess={data =>
            setLoadingState({
              state: "resolved",
              data
            })
          }
          onLoadError={error =>
            setLoadingState({
              state: "rejected",
              error
            })
          }
          noData="session_no_file_url" // ToDo
          loading={<Loader fullX />}
          error="session_file_loading_error" // ToDo
        >
          <div style={{ width: width }}>
            <ImageGallery
              items={times(totalPages).map(i => ({
                index: i,
                original: `${i}`
              }))}
              showFullscreenButton={false}
              showPlayButton={false}
              showIndex
              lazyLoad
              disableSwipe
              useTranslate3D={false}
              startIndex={currentPageIndex}
              onSlide={currentIndex => onPageChange(currentIndex)}
              renderItem={({ index }) => (
                <Page
                  width={width}
                  pageIndex={index}
                  loading={
                    <div style={{ width: "100%", minHeight: 400 }}>
                      <Loader full />
                    </div>
                  }
                >
                  <DrawArea workspaceId={workspaceId} sessionId={sessionId} />
                </Page>
              )}
              renderThumbInner={({ index }) => (
                <Page width={100} pageIndex={index} loading={null} />
              )}
            />
          </div>
        </Document>
      </div>
    </div>
  );
}

export default PDFViewer;
