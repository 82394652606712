import React, { useEffect } from "react";
import { Table } from "antd";
import { useAsync } from "react-async";
import { useFunctions } from "reactfire";

function WorkspaceUsers({ workspaceId }) {
  const functions = useFunctions();
  const workspaceUsers = useAsync({
    deferFn: async id => {
      return (await functions.httpsCallable("listWorkspaceUsers")({
        id
      })).data;
    }
  });

  useEffect(() => {
    workspaceUsers.run(workspaceId);
  }, [workspaceId]); // eslint-disable-line

  const columns = [
    {
      title: "ID",
      dataIndex: "uid",
      key: "uid",
      width: 220
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role"
    }
  ];

  return (
    <div>
      <Table
        rowKey="uid"
        columns={columns}
        dataSource={workspaceUsers.data || []}
        loading={workspaceUsers.isPending}
        bordered
        pagination={false}
      />
    </div>
  );
}

export default WorkspaceUsers;
