import React from "react";
import { Redirect } from "react-router-dom";
import { useRecoilState } from "recoil";
import { profileState } from "../store";

function Home() {
  const [profile] = useRecoilState(profileState);

  if (profile.selectedWorkspace) {
    return <Redirect to={`/w/${profile.selectedWorkspace}`} />;
  }

  return <Redirect to="/workspaces" />;
}

export default Home;
