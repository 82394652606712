import React, { useEffect, useRef, useState } from "react";
import { useAsync } from "react-async";
import { useFirestore } from "reactfire";
import { throttle } from "lodash-es";

const containerStyles = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  cursor: "crosshair",
  zIndex: 999
};

const pathStyle = {
  fill: "none",
  stroke: "#8cff32",
  strokeLinejoin: "round",
  strokeLinecap: "round"
};

function DrawArea({ workspaceId, sessionId }) {
  const firestore = useFirestore();
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const containerRef = useRef(null);
  const sessionRef = firestore.doc(
    `workspaces/${workspaceId}/sessions/${sessionId}`
  );
  const setAnnotations = useAsync({
    deferFn: ([annotations = []]) => {
      return sessionRef.set(
        {
          resource: {
            annotations: annotations && JSON.stringify(annotations)
          }
        },
        { merge: true }
      );
    }
  });

  const debounced = useRef(
    throttle((annotations = lines) => {
      setAnnotations.run(annotations);
    }, 200)
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    debounced.current(lines);
  }, [lines]);

  const handleMouseDown = e => {
    if (e.button !== 0) {
      return;
    }

    const point = relativeCoordinatesForEvent(e);

    setLines([...lines, [point]]);
    setIsDrawing(true);
  };

  const handleMouseMove = e => {
    if (!isDrawing) {
      return;
    }

    const point = relativeCoordinatesForEvent(e);

    setLines(
      lines.map((line, i) => {
        if (i === lines.length - 1) {
          return [...line, point];
        }

        return line;
      })
    );
  };

  const handleMouseUp = () => setIsDrawing(false);

  const relativeCoordinatesForEvent = e => {
    const boundingRect = containerRef.current.getBoundingClientRect();

    return {
      x: ((e.clientX - boundingRect.left) / boundingRect.width) * 100,
      y: ((e.clientY - boundingRect.top) / boundingRect.height) * 100
    };
  };

  return (
    <div
      ref={containerRef}
      style={containerStyles}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    >
      <Drawing lines={lines} />
    </div>
  );
}

function Drawing({ lines }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      {lines.map((line, index) => (
        <DrawingLine key={index} line={line} />
      ))}
    </svg>
  );
}

function DrawingLine({ line }) {
  const pathData =
    "M " +
    line
      .map(({ x, y }) => {
        return `${x} ${y}`;
      })
      .join(" L ");

  return <path style={pathStyle} d={pathData} />;
}

export default DrawArea;
