import React, { useEffect } from "react";
import { ConfigProvider, Empty, Table } from "antd";
import { useAsync } from "react-async";
import { useFunctions } from "reactfire";
import { formatFirebaseDate2, getFileNameFromPath } from "../utils";
import { useTranslation } from "react-i18next";

function WorkspaceSessions({ workspaceId }) {
  const { t } = useTranslation();
  const functions = useFunctions();
  const workspaceSessions = useAsync({
    deferFn: async id => {
      return (await functions.httpsCallable("listWorkspaceSessions")({
        id
      })).data;
    }
  });

  useEffect(() => {
    workspaceSessions.run(workspaceId);
  }, [workspaceId]); // eslint-disable-line

  const columns = [
    {
      title: t("date"),
      dataIndex: "createdTime",
      key: "createdTime",
      width: 200,
      render: date => formatFirebaseDate2(date)
    },
    {
      title: t("user"),
      dataIndex: "user",
      key: "user",
      width: 280
    },
    {
      title: t("client"),
      dataIndex: "client",
      key: "client"
    } /*,
    {
      title: "File",
      dataIndex: "resource",
      key: "resource",
      width: 400,
      render: resource =>
        resource && getFileNameFromPath(resource?.filePath, workspaceId)
    }*/
  ];

  return (
    <div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty description="No sessions created by your team yet." />
        )}
      >
        <Table
          rowKey="id"
          columns={columns}
          dataSource={workspaceSessions.data || []}
          loading={workspaceSessions.isPending}
          bordered
          pagination={false}
          size="small"
          expandable={{
            expandedRowRender: ({ id, notes, resource }) => (
              <div className="pa3">
                <div>
                  <div className="f6 fw6 mb2">{t("session_id")}</div>
                  <div>{id}</div>
                </div>
                <div className="mt2">
                  <div className="f6 fw6 mb2">{t("notes")}</div>
                  <div>{notes || <span className="black-30">n/a</span>}</div>
                </div>
                <div className="mt2">
                  <div className="f6 fw6 mb2">{t("file")}</div>
                  <div>
                    {getFileNameFromPath(resource?.filePath, workspaceId) || (
                      <span className="black-30">n/a</span>
                    )}
                  </div>
                </div>
              </div>
            ),
            rowExpandable: () => true
          }}
        />
      </ConfigProvider>
    </div>
  );
}

export default WorkspaceSessions;
